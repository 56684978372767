import { IntlShape } from 'react-intl';
import * as PATHS from 'constants/paths';

const htmlParserOptions = {
  br: () => <br />,
  b: (str: string) => <b>{str}</b>,
  privacy: (str: string) => (
    <a href={PATHS.BASE_FO} target="_blank" rel="noreferrer">
      {str}
    </a>
  ),
};

export const academyTranslations = (intl: IntlShape) => ({
  academy: {
    type: {
      fla: {
        title: intl.formatMessage({
          defaultMessage: 'Academy FLA',
          description: 'Academy Page First Paper Title',
        }),
        firstParagraph: intl.formatMessage({
          defaultMessage:
            "Academy FLA è l'offerta formativa interaziendale che viene progettata semestralmente dall'Ufficio Formazione di Federlegno Arredo Eventi Spa e che propone percorsi di training per le imprese del settore legno-arredo.",
          description: 'Academy Page First Paper First Paragraph',
        }),
        secondParagraph: intl.formatMessage({
          defaultMessage:
            'Sviluppa un lavoro continuo di confronto con le aziende e pianifica corsi di formazione altamente interattivi nelle aree:',
          description: 'Academy Page First Paper Second Paragraph',
        }),
        ul: {
          one: intl.formatMessage({
            defaultMessage: 'Digital, social e innovazione',
            description: 'Academy Page First Paper ul li',
          }),
          two: intl.formatMessage({
            defaultMessage: 'Soft skills e competenze manageriali',
            description: 'Academy Page First Paper ul li',
          }),
          three: intl.formatMessage({
            defaultMessage: 'Sviluppo della forza commerciale, vendite',
            description: 'Academy Page first paper ul li',
          }),
          four: intl.formatMessage({
            defaultMessage: 'Green transition',
            description: 'Academy Page first paper ul li',
          }),
          five: intl.formatMessage({
            defaultMessage: 'Normative di settore',
            description: 'Academy Page first paper ul li',
          }),
        },
        thirdParagraph: intl.formatMessage({
          defaultMessage:
            'Tutti i nostri corsi sono finanziabili con il Conto Formazione di',
          description: 'Academy Page first paper third paragraph',
        }),
        thirdParagraphyLink: intl.formatMessage({
          defaultMessage: 'Fondimpresa.',
          description: 'Academy Page first paper third paragraph link',
        }),
        buttonText: intl.formatMessage({
          defaultMessage: 'Qui la nostra programmazione aggiornata:',
          description: 'Academy Page first paper button text',
        }),
        buttonLabel: intl.formatMessage({
          defaultMessage: 'Scopri tutti i corsi',
          description: 'Academy Page first paper button label',
        }),
        contact: intl.formatMessage({
          defaultMessage: 'Formazione Academy FLA',
        }),
      },
      architects: {
        title: intl.formatMessage({
          defaultMessage: 'Academy Architetti',
          description: 'Academy Page Second Paper Title',
        }),
        firstParagraph: intl.formatMessage({
          defaultMessage:
            "Con Academy Architetti l'Ufficio Formazione di Federlegno Arredo Eventi Spa organizza eventi formativi accreditati presso il CNAPPC a cui partecipano architetti e professionisti del settore legno-arredo. Il servizio offerto alle aziende prevede:",
          description: 'Academy Page second paper first paragraph',
        }),
        ul: {
          one: intl.formatMessage({
            defaultMessage:
              'Apposizione del logo aziendale sul materiale didattico utilizzato durante i webinar',
            description: 'Academy Page Second Paper ul li',
          }),
          two: intl.formatMessage({
            defaultMessage:
              'Invio di materiale promozionale aziendale a tutti i partecipanti',
            description: 'Academy Page Second Paper ul li',
          }),
          three: intl.formatMessage({
            defaultMessage: "Speech di presentazione dell'azienda",
            description: 'Academy Page Second Paper ul li',
          }),
          four: intl.formatMessage({
            defaultMessage:
              "Consegna all'azienda del database profilato di tutti gli iscritti",
            description: 'Academy Page Second Paper ul li',
          }),
        },
        secondParagraph: intl.formatMessage({
          defaultMessage:
            "L'Ufficio Formazione organizza inoltre eventi formativi aziendali presso show-room o direttamente in azienda, occupandosi della docenza e degli inviti agli architetti.",
          description: 'Academy Page second paper second paragraph',
        }),
        thirdParagraph: intl.formatMessage({
          defaultMessage:
            'Costruiamo per voi e con voi attività che vi mettano in relazione diretta con professionisti.',
          description: 'Academy Page second paper third paragraph',
        }),
        contact: intl.formatMessage({
          defaultMessage: 'Formazione Academy Architetti',
        }),
      },
      fundedTraining: {
        title: intl.formatMessage({
          defaultMessage: 'Formazione Finanziata',
          description: 'Academy Page Third Paper title',
        }),
        questionText: intl.formatMessage({
          defaultMessage: "Cos'è Fondimpresa?",
          description: 'Academy Page third paper question text',
        }),
        firstParagraph: intl.formatMessage({
          defaultMessage:
            'È un fondo interprofessionale per la formazione che permette di finanziare tutta la formazione, compresa quella obbligatoria Sicurezza e Affiancamenti.',
          description: 'Academy Page third paper first paragraph',
        }),
        secondParagraph: intl.formatMessage({
          defaultMessage:
            'L’ufficio Formazione di Federlegno Arredo Eventi Spa segue le aziende nella gestione delle pratiche, dalla raccolta dei fabbisogni, fino alla rendicontazione.',
          description: 'Academy Page third paper second paragraph',
        }),
        thirdParagraph: intl.formatMessage({
          defaultMessage:
            'In aggiunta con gli Avvisi di Fondimpresa sono a disposizione, per le aziende aderenti al fondo, altre ore di formazione gratuita su progetti in ambito Innovazione Digitale e Green Transition.',
          description: 'Academy Page third paper third paragraph',
        }),
        fourthParagraph: intl.formatMessage({
          defaultMessage:
            'Aderire è veloce e gratuito, non ci sono quote da pagare ed è sempre reversibile. ',
          description: 'Academy Page third paper fourth paragraph',
        }),
        buttonLabel: intl.formatMessage({
          defaultMessage: 'Scopri come aderire',
          description: 'Academy Page third paper button label',
        }),
        rightSideText: intl.formatMessage({
          defaultMessage:
            'Supportiamo la formazione continua e la rendiamo sostenibile. Aderire è veloce e gratuito, non ci sono quote da pagare ed è sempre reversibile.',
          description: 'Academy Page third paper right side text',
        }),
      },
    },
    ['academy-fla']: {
      title: intl.formatMessage({
        defaultMessage: 'Academy FLA',
        description: 'content Academy FLA title',
      }),
      subtitle: intl.formatMessage(
        {
          defaultMessage:
            "L 'offerta formativa interaziendale che viene progettata semestralmente dall'Ufficio Formazione di Federlegno Arredo Eventi Spa. Qui trovi tutti i percorsi di training per le imprese del settore legno-arredo.<br></br> <b>Tutti i nostri corsi sono finanziabili con il Conto Formazione di Fondimpresa.</b>",
          description: 'content Academy FLA subtitle',
        },
        { ...htmlParserOptions }
      ),
      listCardTitle: intl.formatMessage({
        defaultMessage: 'Le aree dei corsi',
        description: 'content Academy FLA listCardTitle',
      }),
    },
    ['academy-architetti']: {
      title: intl.formatMessage({
        defaultMessage: 'Academy Architetti',
        description: 'content Academy Architetti title',
      }),
      subtitle: intl.formatMessage(
        {
          defaultMessage:
            'Eventi formativi accreditati presso il CNAPPC a cui partecipano architetti e professionisti del settore Legno-Arredo. <br></br> <b>Costruiamo per voi e con voi attività che vi mettano in relazione diretta con professionisti.</b>',
          description: 'content Academy Architetti subtitle',
        },
        { ...htmlParserOptions }
      ),
      listCardTitle: intl.formatMessage({
        defaultMessage: 'Programma eventi 2025',
        description: 'content Academy Architetti listCardTitle',
      }),
    },
    footer: {
      title: intl.formatMessage({
        defaultMessage: 'Fondimpresa:',
        description: 'content Academy footer title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Supportiamo la formazione continua e la rendiamo sostenibile. Aderire è veloce e gratuito, non ci sono quote da pagare ed è sempre reversibile.',
        description: 'content Academy footer subtitle',
      }),
      button: intl.formatMessage({
        defaultMessage: 'Scopri come aderire',
        description: 'content Academy footer button',
      }),
    },
    courseLabel: intl.formatMessage({
      defaultMessage: 'Corso',
      description: 'content Academy course Label',
    }),
    seeAllBtn: intl.formatMessage({
      defaultMessage: 'Vedi tutti',
      description: 'content Academy course seeAllBtn',
    }),
    showAllCourses: intl.formatMessage({
      defaultMessage: 'Scopri tutti i corsi',
      description: 'content Academy course showAllCourses',
    }),
    detail: {
      objectives: intl.formatMessage({
        defaultMessage: 'obiettivi',
        description: 'content Academy detail objectives',
      }),
      prerequisites: intl.formatMessage({
        defaultMessage: 'prerequisiti',
        description: 'content Academy detail prerequisites',
      }),
      topics: intl.formatMessage({
        defaultMessage: 'argomenti',
        description: 'content Academy detail topics',
      }),
      additionalInfo: intl.formatMessage({
        defaultMessage: 'altre informazioni',
        description: 'content Academy detail additionalInfo',
      }),
      teachers: intl.formatMessage({
        defaultMessage: 'docenti',
        description: 'content Academy detail teachers',
      }),
      dates: intl.formatMessage({
        defaultMessage: 'date',
        description: 'content Academy detail date',
      }),
      hour: intl.formatMessage({
        defaultMessage: 'orario',
        description: 'content Academy detail hour',
      }),
      duration: intl.formatMessage({
        defaultMessage: 'durata complessiva',
        description: 'content Academy detail duration',
      }),
      cfp: intl.formatMessage({
        defaultMessage: 'cfp',
        description: 'content Academy detail cfp',
      }),
      cost: intl.formatMessage({
        defaultMessage: 'costo',
        description: 'content Academy detail cost',
      }),
      recipients: intl.formatMessage({
        defaultMessage: 'destinatari',
        description: 'content Academy detail recipients',
      }),
      subscribe: intl.formatMessage({
        defaultMessage: 'Iscriviti',
        description: 'content Academy detail subscribe',
      }),
      contact: intl.formatMessage({
        defaultMessage: 'CONTATTO',
        description: 'content Academy detail contact',
      }),
      reqInfo: intl.formatMessage({
        defaultMessage: 'Richiedi informazioni',
        description: 'content expert detail reqInfo',
      }),
    },
    modal: {
      title: intl.formatMessage({
        defaultMessage: 'RICHIEDI INFORMAZIONI',
        description: 'academy modal title',
      }),
      privacy: intl.formatMessage(
        {
          defaultMessage:
            "Procedendo con l'iscrizione confermi di aver letto e di accettare le <br></br> <privacy>Condizioni di Privacy</privacy>",
          description: 'academy modal privacy',
        },
        { ...htmlParserOptions }
      ),
    },
  },
  academyActions: {
    finance: {
      title: intl.formatMessage({
        defaultMessage: 'Richiesta di finanziamento con Fondoimpresa',
        description: 'Academy Actions finance Title',
      }),
      breadcrumbs: intl.formatMessage({
        defaultMessage: 'Richiesta Fondoimpresa',
        description: 'Academy Actions finance breadcrumbs',
      }),
      course: intl.formatMessage({
        defaultMessage: 'Corso',
        description: 'Academy Actions finance course',
      }),
      cost: intl.formatMessage({
        defaultMessage: 'Costo',
        description: 'Academy Actions finance cost',
      }),
      firstSign: intl.formatMessage({
        defaultMessage: 'prima iscrizione',
        description: 'Academy Actions finance firstSign',
      }),
      sales: intl.formatMessage({
        defaultMessage: 'di sconto dalla seconda',
        description: 'Academy Actions finance sales',
      }),
      sendReq: intl.formatMessage({
        defaultMessage: 'Invia la richiesta',
        description: 'Academy Actions finance sendReq',
      }),
      boxTitle: intl.formatMessage({
        defaultMessage: 'Conto Formazione',
        description: 'Academy Actions finance boxTitle',
      }),
      boxSubtitle: intl.formatMessage({
        defaultMessage:
          "È un conto a disposizione delle aziende aderenti a Fondimpresa, che consente di gestire le risorse finanziarie per la formazione dei propri lavoratori direttamente con una procedura online, in modo autonomo e immediato. L'importo non utilizzato entro i due anni dall’accredito viene trasferito dal Conto Formazione aziendale al Conto di Sistema (conto collettivo).",
        description: 'Academy Actions finance boxSubtitle',
      }),
      infoTitle: intl.formatMessage({
        defaultMessage: 'Richiesta di informazioni',
        description: 'Academy Actions finance boxTitle',
      }),
    },
  },
});
