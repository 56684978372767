import { isToolEnabled } from './user';
import {
  SideBarDashboardIcon,
  SideBarChartIcon,
  SideBarAcademyIcon,
  SideBarFinanceIcon,
  SideBarCopyrightIcon,
  SideBarBookIcon,
  SideBarLibraryIcon,
  SideBarCircolarityIcon,
  SideBarTreesIcon,
  SideBarSurveyIcon,
  SideBarCertificationsIcon,
  SideBarSportelloIcon,
  SideBarAiIcon,
} from 'assets/icons';
import SideBarBagIcon from 'assets/icons/SidebarIcon/SideBarBagIcon';
import * as PATHS from 'constants/paths';

export const navBarItem = [
  {
    label: 'Dashboard',
    path: PATHS.DASHBOARD,
    icon: SideBarDashboardIcon,
    ref: 'dashboard',
  },
  {
    label: 'Centro Studi',
    path: PATHS.STUDYCENTER,
    icon: SideBarChartIcon,
    ref: 'studyCenter',
  },
  {
    label: 'Academy',
    path: PATHS.ACADEMY,
    icon: SideBarAcademyIcon,
    ref: 'academy',
  },
  {
    label: 'Finanza',
    path: PATHS.FINANCEANDTAX,
    icon: SideBarFinanceIcon,
    ref: 'finance',
  },
  {
    label: 'Proprietà Intellettuale',
    path: PATHS.INTELLECTUALPROPERTY,
    icon: SideBarCopyrightIcon,
    ref: 'intellectualProperty',
  },
  // {
  //   label: 'Biblioteca',
  //   path: PATHS.EDUCATION,
  //   icon: SideBarBookIcon,
  //   ref: 'library',
  // },
  {
    label: 'Libreria Materiali',
    path: PATHS.MATERIALLIBRARY,
    icon: SideBarLibraryIcon,
    ref: 'materialLibrary',
  },
  {
    label: 'Tool Circolarità',
    path: PATHS.CIRCULARITYTOOL,
    icon: SideBarCircolarityIcon,
    ref: 'circularityTool',
    // TODO: ACTIVE TOOL
    disabled: !isToolEnabled,
  },
  {
    label: 'Bosco del Design',
    path: PATHS.DESIGNWOODS,
    icon: SideBarTreesIcon,
    ref: 'designWood',
  },
  {
    label: 'Survey Generale',
    path: PATHS.GENERALSURVEY,
    icon: SideBarSurveyIcon,
    ref: 'generalSurvey',
    disabled: true,
  },
  {
    label: 'Sportello Doganale',
    path: PATHS.CUSTOMHOUSEOFFICE,
    icon: SideBarSportelloIcon,
    ref: 'customHouseOffice',
  },
  {
    label: 'Portale Certificazioni',
    path: PATHS.CERTIFICATION,
    icon: SideBarCertificationsIcon,
    ref: 'certifications',
  },
  {
    label: 'Borsa Italiana del Legno',
    path: PATHS.ITALIAN_WOOD_STOCK,
    icon: SideBarBagIcon,
    ref: 'italianWoodExchange',
  },

  {
    label: 'Glossario',
    path: '/',
    icon: SideBarBookIcon,
    ref: 'glossary',
  },
];

export const aiItems = [
  {
    label: 'Ai Concierge',
    path: '/',
    icon: SideBarAiIcon,
    ref: 'aiConcierge',
  },
];

export const HIGHLIGHT_COLOR = '#FBBC05';
